<template>
  <div class="privitePolicyWrapper">
    <div class="privitePolicyContainer">
      <p class="headerTitle">Cookies Policy</p>
      <section class="paragraph">
        Welcome to
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">
          Boolv.tech
        </a>
        , as applicable (the "Site"). The Site is owned and operated by
        <a class="linkName" href="https://boolv.tech/#/" target="_blanck">
          Boolv.tech
        </a>
        Ltd. ("Boolv", "us", "our", "we"). The Site uses various types of cookies which help us administer and operate
        it, as described herein.
      </section>
      <p class="title">What Are Cookies</p>
      <section class="paragraph">
        Cookies are small text files sent by a web server to your browser when you access the Site. Different types of
        cookies may be used for different purposes, such as to -
      </section>
      <ul>
        <li class="listItem">Collect statistical information about your use of the Site;</li>
        <li class="listItem">Save you the need to re-enter your login information on the Site;</li>
        <li class="listItem">
          Remember your actions and preferences on the Site over a period of time, so you don't have to keep re-entering
          them whenever you come back to the Site; and
        </li>
        <li class="listItem">
          Collect behavioral information about your surfing on other sites which then help advertisers deliver ads that
          are more relevant to you.
        </li>
      </ul>

      <section class="paragraph">
        Cookies are classified into two major categories: session cookies and persistent cookies. Session cookies are
        temporarily stored on your device while you are visiting a given site. They are deleted once you leave the site.
        Persistent cookies, on the other hand, remain on your device for a more extended period of time specified in the
        cookie file, after you leave the site. This type of cookie remains available for retrieval by the web server
        when you return to visit the website.
      </section>

      <section class="paragraph">
        Cookies are further classified into "first party cookies" and "third party cookies". First party cookies are
        those sent to your browser by the server of the site you are visiting. Third party cookies are those sent to
        your browser by servers other than of the site you are visiting (e.g., the server of an advertising network to
        which the site you are visiting is subscribed).
      </section>

      <p class="title">How We Use Cookies</p>

      <section class="paragraph">
        We use first party cookies for Site analytics purposes, such as collecting information pertaining to your
        activities on our Site, including your session durations, the Site content you accessed and your interactions
        and user-interface clicks with respect to such content, the Internet protocol (IP) address of the device from
        which you accessed our Site, the browser you used, its version and language (i.e. locale) preferences, and the
        approximate geographical area from which you accessed the Site.
      </section>

      <section class="paragraph">
        We also use third party cookies for marketing and advertisement purposes, in order to deliver to you more
        relevant ads for our products and services as you surf the web (this is known as "retargeting").
      </section>

      <p class="title">How To Control Cookies</p>

      <section class="paragraph">
        If you wish to block cookies, you may do so through your browser's settings. You can delete cookies that are
        already on your device and you can set your browser to prevent them from being placed thereafter. Please refer
        to the browser's help menu for further information. Some of the third party cookies services listed above
        provide an option of opting-out of their cookies. Please refer to the links below for more information. Please
        also bear in mind that disabling cookies may adversely affect your user experience on the Site.
      </section>

      <p class="smallTitle">Here are some more details about the cookies we use -</p>
      <section class="paragraph">
        See more details about the
        <a href="https://policies.google.com/technologies/cookies#types-of-cookies" _target="_blanck">
          types of cookies used by Google
        </a>
        and its partners and how
        <a href="https://policies.google.com/technologies/cookies" _target="_blanck">
          they use them
        </a>
        . You can also read more about and how Google
        <a href="https://policies.google.com/technologies/ads?hl=en" target="_blanck">
          uses
        </a>
        advertising cookies. For information about opting-out of Google Analytics cookies, click
        <a href="https://tools.google.com/dlpage/gaoptout/" target="_blanck">

          here
        </a>
        . For information about opting-out of cookies Google uses for advertising, click
        <a href="https://adssettings.google.com/authenticated?hl=en" target="_blanck">
          here
        </a>
        .
      </section>

      <p class="title">Contact Us</p>

      <section class="paragraph ">
        You may contact us with any questions or comments, at:
        <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
          support@boolvector.com
        </a>
      </section>
      <section class="paragraph">Last Updated: July 10, 2022</section>
    </div>
  </div>
</template>
<script setup>
import store from '../../store'
window.addEventListener("scroll", () => {
  store.dispatch('closeSide', { isCollapse: true })
});
</script>
<style scoped lang="less">
.privitePolicyWrapper {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  font-family: 'Helvetica Neue';
  text-align: left;
}

.privitePolicyContainer {
  max-width: 958px;
}

.headerTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 36px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
  padding-bottom: 24px;
}

.title {
  font-weight: 500;
  font-size: 26px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 24px;
}

.smallTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  padding-bottom: 12px;
}

.table {

  tr,
  td,
  th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
  }
}

.anchorPoint {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #875eff;
  padding-bottom: 36px;
}

.linkName {
  color: #875eff;
}

.fontBold {
  font-weight: 500;
}

.listItem {
  padding-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.subListItem {
  text-indent: 1.5rem;
}

.subListItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

a {
  color: #875eff;

  &:hover {
    color: #a987ff;
  }
}

ul {
  list-style: none
}
</style>